import React from 'react';

function NotFound () {
  return (
    <>
      <h1 className='title'>Content Not Found</h1>
    </>
  );
}

export default NotFound;